import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cmOrigin: '',
    previousUrl: '',
    apiUrl: '',
};

export const originalURLSlice = createSlice({
    name: 'originalURL',
    initialState,
    reducers: {
        changeCmOriginURL: (state, action) => {
            state.cmOrigin = action.payload;
        },
        changePreviousUrl: (state, action) => {
            state.previousUrl = action.payload;
        },
        changeApiUrl: (state, action) => {
            state.apiUrl = action.payload;
        },
    },
});

export const originURL = (state) => state.originalURL;
export const { changeCmOriginURL, changePreviousUrl, changeApiUrl } = originalURLSlice.actions;
export default originalURLSlice.reducer;
